<template lang="">
  <div class="qrcode">
    如您有任何问题或建议，请扫码添加用户支持微信：<br /><br /><br />
    <img src="../assets/qrcode.jpg" />
  </div>
</template>

<script>
export default {
  created () {
    document.title = '用户支持 - 帛马'
  }
}
</script>

<style scope="scoped">
.qrcode {
  font-family: '微软雅黑';
  font-size: 14px;
}
.qrcode img {
    height: 140px;
    width: 140px;
}
</style>
