<template>
  <div>
    <div class="content-area">
      <el-alert
        title="提示：您尚未登录，内容将无法保存，为避免内容丢失，请您登录后再编辑画布。"
        type="warning"
        show-icon
        v-if="!isLogin"
      ></el-alert>
      <el-alert
        title="提示：当前模板为VIP用户专享模板，免费用户暂时无法编辑，请升级后使用。"
        type="warning"
        show-icon
        v-if="disable"
      ></el-alert>
      <!-- <el-alert
        title="提示：您当前访问的是模板页面，内容尚未保存。为避免内容丢失，请您先保存文档。"
        type="warning"
        show-icon
        v-if="isLogin && !disable"
      ></el-alert> -->
      <div class="card">
        <div class="page-header">
          <div
            style="display: flex; justify-content: left; align-content: center"
          >
            <p>
              <span
                :contenteditable="editTempTitle"
                id="tempTitle"
                @input="changeTemplateTitle"
                >{{ doc.template_title }}</span
              >：
            </p>
            <input
              class="page-title"
              v-model="doc.doc_title"
              v-on:input="autoSave"
              :disabled="disable"
            />
          </div>
          <div class="operation-area">
            <el-button-group class="btn-group" v-if="activateCanvasEdit">
              <el-tooltip
                effect="dark"
                content="新增模块"
                placement="top-start"
              >
                <el-button
                  type="primary"
                  icon="el-icon-plus"
                  size="small"
                  @click="addItem"
                ></el-button>
              </el-tooltip>
              <!-- <el-tooltip
                effect="dark"
                :content="
                  verticalCompact ? '关闭自动向上对齐' : '开启自动向上对齐'
                "
                placement="top-start"
              >
                <el-button
                  type="primary"
                  icon="el-icon-top"
                  size="small"
                  @click="toggleVerticalCompact"
                ></el-button>
              </el-tooltip> -->
              <el-tooltip
                effect="dark"
                :content="doc.isFrequentUse ? '取消推荐' : '设为推荐'"
                placement="top-start"
              >
                <el-button
                  type="primary"
                  icon="el-icon-s-flag"
                  size="small"
                  @click="toggleIsFrequentUse"
                ></el-button>
              </el-tooltip>
              <el-tooltip
                effect="dark"
                :content="doc.isFree ? '设为VIP模板' : '设为免费模板'"
                placement="top-start"
              >
                <el-button
                  type="primary"
                  icon="el-icon-coin"
                  size="small"
                  @click="toggleIsFree"
                ></el-button>
              </el-tooltip>
              <el-tooltip
                effect="dark"
                content="删除此模板"
                placement="top-start"
              >
                <el-button
                  type="primary"
                  icon="el-icon-delete"
                  size="small"
                  @click="confirmDelete"
                ></el-button>
              </el-tooltip>
            </el-button-group>
            <el-button
              type="primary"
              size="small"
              @click="toggleEdit"
              v-if="showEditBtn"
              >{{ activateCanvasEdit ? '结束编辑' : '编辑模板' }}</el-button
            >
            <el-button
              type="primary"
              size="small"
              @click="createTemp"
              v-if="showEditBtn"
              >存新模板</el-button
            >
            <el-button
              type="primary"
              size="small"
              @click="updateTemp"
              v-if="showEditBtn"
              >保存模板</el-button
            >
            <el-button type="primary" size="small" @click="notifySave"
              >导出图片</el-button
            >
            <el-button type="primary" size="small" @click="notifySave"
              >导出Word</el-button
            >
            <el-button type="primary" size="small" @click="notifySave"
              >导出PPT</el-button
            >
            <el-button type="primary" size="small" @click="notifySave"
              >编辑模板</el-button
            >
            <!-- <el-button type="primary" size="small" @click="confirmReturn"
              >返回</el-button
            > -->
            <el-button type="primary" size="small" @click="confirmSave"
              >保存文档</el-button
            >
          </div>
        </div>
        <div style="width: 100%; margin-top: 0px; margin-bottom: 20px; height: 100%" id="pdfDom">
          <grid-layout
            :layout.sync="doc.layout"
            :col-num="doc.colNum"
            :row-height="100"
            :is-draggable="draggable"
            :is-resizable="resizable"
            :responsive="false"
            :vertical-compact="verticalCompact"
            :use-css-transforms="true"
            :margin="[4, 4]"
          >
            <grid-item
              v-for="item in doc.layout"
              :static="item.static"
              :x="item.x"
              :y="item.y"
              :w="item.w"
              :h="item.h"
              :i="item.i"
              drag-allow-from=".drag-area"
              drag-ignore-from=".no-drag"
              :key="item.index"
              @resized="autoSave"
              @moved="autoSave"
              :style="{ 'z-index': activeItem == item.i ? 999 : 0 }"
            >
              <div class="grid-content">
                <div class="content-title">
                  <input
                    class="content-title-text"
                    type="text"
                    v-model="item.title"
                    :disabled="!titleEditable"
                    v-on:input="autoSave"
                  />
                  <div class="drag-area" v-if="draggable">
                    <span class="el-icon-rank"></span>
                  </div>
                  <div class="remove" v-if="removable">
                    <span
                      class="el-icon-close"
                      @click="removeItem(item.i)"
                    ></span>
                  </div>
                </div>
                <div class="content-text">
                  <quill-editor
                    class="text-area"
                    ref="myTextEditor"
                    v-model="item.content"
                    :options="editorOption"
                    @change="autoSave"
                    :disabled="disable"
                    @focus="onEditorFocus(item.i)"
                  />
                  <!-- :disabled="!isLogin" -->
                </div>
              </div>
            </grid-item>
          </grid-layout>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { GridLayout, GridItem } from 'vue-grid-layout'
import { quillEditor } from 'vue-quill-editor'
import 'quill/dist/quill.bubble.css'
import TempService from '../util/TempService'
import TokenService from '../util/TokenService'
import DocService from '../util/DocService'
export default {
  components: {
    GridLayout,
    GridItem,
    quillEditor
  },
  data () {
    return {
      disable: false,
      isLogin: this.$store.getters.getIsLogin,
      token: null,
      showEditBtn: false,
      editTempTitle: false,
      pageData: null,
      doc: {
        doc_title: '',
        template_title: null,
        category: 'default',
        layout: [],
        isFrequentUse: false,
        isFree: false
      },
      activateCanvasEdit: false,
      draggable: false,
      resizable: false,
      removable: false,
      titleEditable: false,
      verticalCompact: true,
      index: 0,
      colNum: 12,
      activeItem: 0,
      editorOption: {
        theme: 'bubble',
        placeholder: '',
        modules: {
          toolbar: [
            ['bold', 'italic', 'underline'],
            [{ list: 'ordered' }, { list: 'bullet' }],
            [{ align: [] }],
            [{ color: [] }, { background: [] }],
            // ['image'],
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            ['clean']
          ]
        }
      }
    }
  },
  async created () {
    // 获取token，没有也没关系
    const token = await TokenService.checkToken()
    this.token = token
    // 从state中获取用户组
    const userGroup = this.$store.getters.getUserGroup
    // 如果是admin，就显示模板编辑按钮，如果不是就不显示
    if (userGroup === '管理员') {
      this.showEditBtn = true
    }
    // 不需要登录就可以获得模板列表
    const temp = await TempService.getOneTemp(this.$route.params.id)
    if (temp.code === 70000) return this.$router.push('/template')
    this.doc = temp
    // 如果模板非免费模板，用户又是免费用户，把disable设置成true禁止用户编辑
    if (this.doc.isFree === false && userGroup === '免费用户') {
      this.disable = true
    }
    // 更新页面title
    document.title = this.doc.template_title + ' - 帛马'
    // 获取当前文档所有box里最大的那个box的i，设置到this.index，否则增减的时候会出现重复的i
    const boxData = this.doc.layout
    const maxItem = boxData.reduce((prev, cur) => {
      return prev.i > cur.i ? prev : cur
    })
    this.index = parseInt(maxItem.i) + 1
  },
  methods: {
    // 修改模板标题
    changeTemplateTitle () {
      this.doc.template_title = document.getElementById('tempTitle').innerText
    },
    // 提示保存后才能导出图片
    notifySave () {
      // 没有登录就引导去登录
      this.$alert('请先保存文档。', '提示', {
        confirmButtonText: '知道了'
      })
    },
    // 保存文档时确认登录状态
    async confirmSave () {
      // 如果模板不允许编辑，也不允许保存
      if (this.disable === true) {
        this.$confirm('当前模板为VIP用户专享模板，请升级后使用。', '提示', {
          confirmButtonText: '查看详情',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.$router.push('/price')
        }).catch(() => { })
        return
      }
      if (this.isLogin) {
        const verifyCreate = await DocService.verifyCreate(this.token)
        // 如果返回70002，表示免费用户已经超限
        if (verifyCreate.code === '70002') {
          this.$confirm('您的免费文档数量已用完，请升级后继续使用。', '提示', {
            confirmButtonText: '查看详情',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            this.$router.push('/price')
          }).catch(() => { })
          return
        } else if (verifyCreate.code === '70001') {
          // 如果返回70001，表示VIP用户过期了
          this.$confirm('您的VIP权限已过期，无法创建新文档，是否续费？', '提示', {
            confirmButtonText: '立即续费',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            this.$router.push('/price')
          }).catch(() => { })
          return
        }
        // 已经登录直接保存文档
        this.saveToDoc()
      } else {
        // 没有登录询问是否去登录或取消
        this.$confirm('请登录后操作。', '提示', {
          distinguishCancelAndClose: true,
          confirmButtonText: '前往登录页',
          cancelButtonText: '取消',
          type: 'warning'
        })
          .then(() => {
            localStorage.setItem('from', this.$route.path)
            this.$router.push('/login')
          })
          .catch(() => {})
      }
    },
    // 返回前确认
    async confirmReturn () {
      if (this.isLogin) {
        const verifyCreate = await DocService.verifyCreate(this.token)
        this.$confirm('是否保存当前内容为新文档？', '提示', {
          distinguishCancelAndClose: true,
          confirmButtonText: '保存',
          cancelButtonText: '不保存',
          type: 'warning'
        })
          .then(() => {
            // 如果超出文档数量限制，不让保存了就
            // 如果返回70002，表示免费用户已经超限
            if (verifyCreate.code === '70002') {
              this.$confirm('免费用户仅支持3个文档，是否升级为VIP用户？', '提示', {
                confirmButtonText: '立即升级',
                cancelButtonText: '取消',
                type: 'warning'
              }).then(() => {
                this.$router.push('/price')
              }).catch(() => { })
              return
            } else if (verifyCreate.code === '70001') {
              // 如果返回70001，表示VIP用户过期了
              this.$confirm('您的VIP权限已过期，无法创建新文档，是否续费？', '提示', {
                confirmButtonText: '立即续费',
                cancelButtonText: '取消',
                type: 'warning'
              }).then(() => {
                this.$router.push('/price')
              }).catch(() => { })
              return
            }
            // 如果没超过，就保存文档
            this.saveToDoc('template')
          })
          .catch((action) => {
            if (action === 'cancel') window.location.href = '/#/template'
          })
      } else {
        // 没有登录就引导去登录
        this.$confirm('您尚未登录，文档无法保存，是否前往登录页？', '提示', {
          distinguishCancelAndClose: true,
          confirmButtonText: '前往登录页',
          cancelButtonText: '直接返回',
          type: 'warning'
        })
          .then(() => {
            // 设置来源
            localStorage.setItem('from', this.$route.path)
            this.$router.push('/login')
          })
          .catch((action) => {
            // 去模板列表页
            if (action === 'cancel') window.location.href = '/#/template'
          })
      }
    },
    // 保存模板内容成doc
    async saveToDoc (nextStep) {
      const docToSave = {
        doc_title: this.doc.doc_title,
        layout: this.doc.layout,
        template_id: this.doc._id,
        template_title: this.doc.template_title
      }
      // 将模板页填的内容保存成新文档，并跳转到文档页
      const doc = await DocService.addDoc(this.token, docToSave)
      if (nextStep === 'template') {
        // 如果点的是“返回”，保存doc后回到模板列表页
        this.$router.push('/template')
        this.$message({
          message: '文档已保存，请在我的文档中查看',
          type: 'success',
          offset: 52
        })
      } else {
        // 如果点的是“保存文档”，保存文档后去文档详情页
        localStorage.setItem('saveFromTemp', true)
        this.$router.push('/doc/' + doc._id)
        this.$message({
          message: '文档已保存',
          type: 'success',
          offset: 52
        })
      }
    },
    // --------------以下是对模板的操作---------------
    // 另存为新模板
    async createTemp () {
      const tempToSave = {
        doc_title: this.doc.doc_title,
        template_title: this.doc.template_title,
        category: this.doc.category,
        layout: this.doc.layout,
        isFrequentUse: this.doc.isFrequentUse,
        isDel: false,
        isFree: this.doc.isFree,
        sequence: 1
      }
      // 用当前模板的数据，创建一个新的模板，并跳转到新的模板页面
      const temp = await TempService.createTemp(this.token, tempToSave)
      this.$router.push('/temp/' + temp._id)
      this.$message({
        message: '新模板保存成功，当前已在编辑新模板',
        type: 'success',
        offset: 52
      })
    },
    // 保存对当前模板的修改
    async updateTemp () {
      // 保存模板
      // console.log(this.doc)
      const temp = await TempService.updateTemp(this.token, this.doc)
      if (temp.code === 60005) {
        return this.$message({
          message: temp.message,
          type: 'success',
          offset: 52
        })
      }
      this.$message({
        message: temp.message,
        type: 'warning',
        offset: 52
      })
    },
    // 删除模板的确认框
    confirmDelete () {
      this.$confirm('此操作将永久删除该模板，是否继续？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.deleteTemp()
        })
        .catch(() => {})
    },
    // 删除模板后返回模板列表
    async deleteTemp () {
      const temp = await TempService.deleteTemp(this.token, this.doc._id)
      if (temp.code === 60005) {
        this.$message({
          type: 'success',
          message: '删除成功',
          offset: 52
        })
        this.$router.push('/template')
      }
    },
    // 自动保存文档
    async autoSave () {
      // console.log('我是自动保存')
    },
    // -----------------以下是vue grid layout自带的方法------------------
    addItem: function () {
      // Add a new item. It must have a unique key!
      this.doc.layout.push({
        x: (this.doc.layout.length * 2) % (this.colNum || 12),
        y: this.doc.layout.length + (this.colNum || 12), // puts it at the bottom
        w: 2,
        h: 2,
        i: this.index,
        title: '新标题',
        content: ''
      })
      // Increment the counter to ensure key is always unique.
      this.index++
      this.autoSave()
    },
    removeItem: function (val) {
      const index = this.doc.layout.map((item) => item.i).indexOf(val)
      this.doc.layout.splice(index, 1)
      this.autoSave()
    },
    toggleIsFrequentUse () {
      this.doc.isFrequentUse = !this.doc.isFrequentUse
      this.autoSave()
    },
    toggleIsFree () {
      this.doc.isFree = !this.doc.isFree
      this.autoSave()
    },
    toggleEdit () {
      this.activateCanvasEdit = !this.activateCanvasEdit
      this.draggable = !this.draggable
      this.resizable = !this.resizable
      this.removable = !this.removable
      this.titleEditable = !this.titleEditable
      this.editTempTitle = !this.editTempTitle
      this.autoSave()
    },
    toggleVerticalCompact () {
      this.verticalCompact = !this.verticalCompact
      this.autoSave()
    },
    // onEditorChange (i, { html, text }) {
    //   this.autoSave()
    // },
    onEditorFocus (i) {
      this.activeItem = i
    },
    moveEvent: function (i, newX, newY) {
      const msg = 'MOVE i=' + i + ', X=' + newX + ', Y=' + newY
      this.eventLog.push(msg)
    },
    movedEvent: function (i, newX, newY) {
      const msg = 'MOVED i=' + i + ', X=' + newX + ', Y=' + newY
      this.eventLog.push(msg)
    },
    resizeEvent: function (i, newH, newW, newHPx, newWPx) {
      const msg =
        'RESIZE i=' +
        i +
        ', H=' +
        newH +
        ', W=' +
        newW +
        ', H(px)=' +
        newHPx +
        ', W(px)=' +
        newWPx
      this.eventLog.push(msg)
    },
    resizedEvent: function (i, newX, newY, newHPx, newWPx) {
      const msg =
        'RESIZED i=' +
        i +
        ', X=' +
        newX +
        ', Y=' +
        newY +
        ', H(px)=' +
        newHPx +
        ', W(px)=' +
        newWPx
      this.eventLog.push(msg)
    },
    containerResizedEvent: function (i, newH, newW, newHPx, newWPx) {
      const msg =
        'CONTAINER RESIZED i=' +
        i +
        ', H=' +
        newH +
        ', W=' +
        newW +
        ', H(px)=' +
        newHPx +
        ', W(px)=' +
        newWPx
      this.eventLog.push(msg)
      // console.log(msg)
    },
    layoutCreatedEvent: function (newLayout) {
      this.eventLog.push('Created layout')
      // console.log('Created layout: ', newLayout)
    },
    layoutBeforeMountEvent: function (newLayout) {
      this.eventLog.push('beforeMount layout')
      // console.log('beforeMount layout: ', newLayout)
    },
    layoutMountedEvent: function (newLayout) {
      this.eventLog.push('Mounted layout')
      // console.log('Mounted layout: ', newLayout)
    },
    layoutReadyEvent: function (newLayout) {
      this.eventLog.push('Ready layout')
      // console.log('Ready layout: ', newLayout)
    },
    layoutUpdatedEvent: function (newLayout) {
      this.eventLog.push('Updated layout')
      // console.log('Updated layout: ', newLayout)
    }
  },
  mounted () {
    // 支持ctrl+s快捷键保存
    document.onkeydown = (e) => {
      const key = window.event.keyCode
      if (key === 83 && e.ctrlKey && this.$route.name === 'TempCanvas') {
        window.event.preventDefault()
        this.confirmSave()
      }
    }
  },
  computed: {
    editor () {
      return this.$refs.myTextEditor.quill
    }
  }
}
</script>

<style lang="scss" scoped>
.card {
  border: 0px;
  border-radius: 5px;
  background-color: #fff;
}
.vue-grid-item:not(.vue-grid-placeholder) {
  background: #e5e5e5;
  border-radius: 5px;
}
.vue-grid-item .resizing {
  opacity: 0.9;
}
.vue-grid-item .static {
  background: #cce;
}
.vue-grid-item .grid-content {
  font-size: 24px;
  text-align: center;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  height: 100%;
  width: 100%;
  background-color: #e5e5e5;
  border-radius: 5px;
}
.vue-grid-item .content-title {
  display: flex;
  justify-content: space-between;
}
.vue-grid-item .content-text {
  height: calc(100% - 42px);
  width: 100%;
}
.content-title-text {
  width: calc(100% - 42px);
  height: 40px;
  border: 0px;
  background-color: #e5e5e5;
  margin-left: 6px;
  font-size: 16px;
  font-weight: 600;
  color: #292929;
}
.content-title-text:focus {
  outline: 0px;
}
.vue-grid-item .minMax {
  font-size: 12px;
}
.vue-grid-item .add {
  cursor: pointer;
}
.drag-area {
  font-size: 16px;
  background-origin: content-box;
  box-sizing: border-box;
  cursor: pointer;
  color: gray;
  background-color: #e5e5e5;
  margin-top: 12px;
  margin-right: 6px;
}
.remove {
  font-size: 16px;
  margin-top: 12px;
  margin-right: 6px;
  background-origin: content-box;
  box-sizing: border-box;
  cursor: pointer;
  color: gray;
  background-color: #e5e5e5;
}
.page-header {
  margin-top: 10px;
  margin-bottom: 6px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.page-header p {
  font-family: '微软雅黑';
  font-size: 20px;
  font-weight: bold;
  margin-left: 10px;
  padding-top: 7px;
}
.page-title {
  font-size: 20px;
  font-weight: 600;
  line-height: 40px;
  // margin-left: 10px;
  color: #292929;
  border: 0px;
  background-color: #fff;
  width: 320px;
}
.page-title:focus {
  outline: 0px;
}
.operation-area {
  margin-right: 6px;
}
.btn-group {
  margin-right: 10px;
}
.text-area {
  width: calc(100% - 4px);
  height: 100%;
  box-sizing: border-box;
  border: 0px;
  resize: none;
  margin: 0 auto 0 auto;
  font-size: 12px;
  padding: 2px;
  background-color: #fff;
  border-bottom-left-radius: 3px;
  border-top-right-radius: 3px;
}
</style>
