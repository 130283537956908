<template lang="">
  <div class="nav" v-show="showNav">
    <div class="nav-bar">
      <div class="logo">
        <a href="/#/template"><img src="../assets/logo.png" alt="" /></a>
      </div>
      <div class="nav-menu">
        <el-menu
          :default-active="$route.path"
          class="el-menu-demo"
          mode="horizontal"
          background-color="#545c64"
          text-color="#fff"
          active-text-color="#ffd04b"
          router
        >
          <!-- <el-menu-item index="/">首页</el-menu-item> -->
          <el-menu-item index="/template">模板中心</el-menu-item>
          <el-menu-item index="/document">我的文档</el-menu-item>
          <el-menu-item index="/recycle">回收站</el-menu-item>
          <el-menu-item index="/price">&nbsp;价格&nbsp;</el-menu-item>
          <el-menu-item index="/contact">用户支持</el-menu-item>
          <!-- <el-menu-item>
            <template>
              <a href="http://boma20190627.mikecrm.com/U37uNto" target="_blank">建议反馈</a>
            </template>
          </el-menu-item> -->
        </el-menu>
      </div>
      <!-- <div class="old-version-entry"><a href="http://v1.boomar.cn" target="_blank">旧版入口</a></div> -->
      <div class="user">
        <a v-if="!$store.getters.getIsLogin" @click="navToLogin"
          ><span class="login">注册 / 登录</span></a
        >
        <el-dropdown v-if="$store.getters.getIsLogin">
          <span class="el-dropdown-link" style="color: #fff">
            {{ $store.getters.getPhone
            }}<i class="el-icon-arrow-down el-icon--right"></i>
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item disabled style="color: #aaa"
              >用户组：{{ $store.getters.getUserGroup }}</el-dropdown-item
            >
            <el-dropdown-item
              disabled
              style="color: #aaa"
              v-show="$store.getters.getUserGroupExpiredAt"
              >有效期至：{{
                expireDate
              }}</el-dropdown-item
            >
            <el-dropdown-item divided @click.native="logout"
              >退出登录</el-dropdown-item
            >
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      showNav: true,
      expireDate: ''
    }
  },
  created () {
    const expireTimeStamp = this.$store.getters.getUserGroupExpiredAt
    this.expireDate = new Date(parseInt(expireTimeStamp)).toLocaleDateString()
  },
  mounted () {
  },
  methods: {
    navToLogin () {
      if (this.$route.path === '/login') return
      localStorage.setItem('from', this.$route.path)
      return this.$router.push('/login')
    },
    toggleNav () {
      this.showNav = !this.showNav
    },
    logout () {
      this.$store.dispatch('setIsLogin', false)
      this.$store.dispatch('setUserGroup', null)
      this.$store.dispatch('setUserGroupExpiredAt', null)
      this.$store.dispatch('setPhone', null)
      localStorage.removeItem('token')
      localStorage.removeItem('refreshToken')
      localStorage.removeItem('isLogin')
      localStorage.removeItem('userGroup')
      localStorage.removeItem('userGroupExpiredAt')
      localStorage.removeItem('phone')
      localStorage.removeItem('from')
      if (this.$route.path !== '/template') {
        this.$router.push('/template')
      }
      this.$message({
        message: '退出登录成功',
        type: 'success',
        offset: 52
      })
    }
  },
  watch: {
    '$store.state.user.userGroupExpiredAt' (newVal, oldVal) {
      this.expireDate = new Date(parseInt(newVal)).toLocaleDateString()
    }
  }
}
</script>

<style scope="scoped">
.el-dropdown-link {
  cursor: pointer;
  color: #409eff;
}
.el-icon-arrow-down {
  font-size: 12px;
}
.nav {
  width: 100%;
  height: 51px;
  border-bottom: 1px solid #e6e6e6;
  background-color: #545c64;
}
.nav-bar {
  width: 1200px;
  height: 51px;
  margin: 0 auto;
  display: flex;
  align-items: center;
}
.logo {
  width: 200px;
  text-align: left;
  color: #fff;
  font-family: '微软雅黑';
  padding-left: 10px;
}
.logo img {
  margin-top: 6px;
  width: 64px;
  height: 32px;
}
.nav-menu {
  width: 800px;
}
.el-menu {
  height: 52px !important;
}
.el-menu-item {
  height: 52px !important;
  line-height: 52px !important;
  font-family: '微软雅黑';
}
.user {
  line-height: 52px;
  cursor: pointer;
  color: #fff;
  font-family: '微软雅黑';
  font-size: 14px;
  width: 200px;
  display: flex;
  justify-content: center;
}
.login {
  margin-left: 20px;
}
.create-btn {
  width: 92px;
  padding-left: 18px;
}
.user a {
  text-decoration: none;
  color: #fff;
}
.old-version-entry {
  width: 60px;
}
.old-version-entry a {
  text-decoration: none;
  color: #fff !important;
  font-size: 12px;
}
</style>
