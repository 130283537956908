<template>
  <div>
    <div class="content-area" v-loading="loading">
      <div class="card" id="pdfDom">
        <div class="page-header">
          <div
            style="display: flex; justify-content: left; align-content: center"
          >
            <p>
              <!-- <span>{{ doc.template_title }}</span
              >： -->
              <!-- 文档名： -->
            </p>
            <input
              class="page-title"
              v-model="doc.doc_title"
              v-on:input="autoSave"
            />
          </div>
          <div class="operation-area" v-show="showOperationArea">
            <el-button type="primary" size="small" @click="exportPNG"
              >导出图片</el-button
            >
            <el-button type="primary" size="small" @click="exportDocx"
              >导出Word</el-button
            >
            <el-button type="primary" size="small" @click="exportPptx" style="margin-right: 10px;"
              >导出PPT</el-button
            >
            <el-button-group class="btn-group" v-show="onCanvasEdit">
              <el-tooltip
                effect="dark"
                :content="
                  titleEditable ? '关闭修改模块标题' : '开启修改模块标题'
                "
                placement="top-start"
              >
                <el-button
                  type="primary"
                  icon="el-icon-edit-outline"
                  size="small"
                  @click="toggleTitleEditable"
                ></el-button>
              </el-tooltip>
              <!-- <el-tooltip
                effect="dark"
                :content="
                  verticalCompact ? '关闭自动向上排列' : '开启自动向上排列'
                "
                placement="top-start"
              >
                <el-button
                  type="primary"
                  icon="el-icon-top"
                  size="small"
                  @click="toggleVerticalCompact"
                ></el-button>
              </el-tooltip> -->
              <el-tooltip
                effect="dark"
                :content="
                  draggable ? '关闭拖动模块' : '开启拖动模块'
                "
                placement="top-start"
              >
                <el-button
                  type="primary"
                  icon="el-icon-rank"
                  size="small"
                  @click="toggleDraggable"
                ></el-button>
              </el-tooltip>
              <el-tooltip
                effect="dark"
                :content="
                  resizable ? '关闭调整模块大小' : '开启调整模块大小'
                "
                placement="top-start"
              >
                <el-button
                  type="primary"
                  icon="el-icon-crop"
                  size="small"
                  @click="toggleResizable"
                ></el-button>
              </el-tooltip>
              <el-tooltip
                effect="dark"
                :content="
                  removable ? '关闭删除模块' : '开启删除模块'
                "
                placement="top-start"
              >
                <el-button
                  type="primary"
                  icon="el-icon-close"
                  size="small"
                  @click="toggleRemovable"
                ></el-button>
              </el-tooltip>
              <el-tooltip
                effect="dark"
                content="新增模块"
                placement="top-start"
              >
                <el-button
                  type="primary"
                  icon="el-icon-plus"
                  size="small"
                  @click="addItem"
                ></el-button>
              </el-tooltip>
              <el-tooltip
                effect="dark"
                content="删除文档"
                placement="top-start"
              >
                <el-button
                  type="primary"
                  icon="el-icon-delete"
                  size="small"
                  @click="confirmDelete"
                ></el-button>
              </el-tooltip>
            </el-button-group>
            <el-button type="primary" size="small" @click="startEdit" v-if="!onCanvasEdit">编辑模板</el-button>
            <el-button type="primary" size="small" @click="endEdit" v-if="onCanvasEdit">结束编辑</el-button>
            <!-- <el-button type="primary" size="small" @click="confirmReturn"
              >返回</el-button
            > -->
            <el-button
              type="primary"
              size="small"
              @click="autoSave('manual', false)"
              >保存文档</el-button
            >
          </div>
        </div>
        <div style="width: 100%; margin-top: 0px; margin-bottom: 20px; height: 100%">
          <grid-layout
            :layout.sync="doc.layout"
            :col-num="doc.colNum"
            :row-height="100"
            :is-draggable="draggable"
            :is-resizable="resizable"
            :responsive="false"
            :vertical-compact="verticalCompact"
            :use-css-transforms="true"
            :margin="[4, 4]"
          >
            <grid-item
              v-for="item in doc.layout"
              :static="item.static"
              :x="item.x"
              :y="item.y"
              :w="item.w"
              :h="item.h"
              :i="item.i"
              drag-allow-from=".drag-area"
              drag-ignore-from=".no-drag"
              :key="item.index"
              @resized="autoSave"
              @moved="autoSave"
              :style="{ 'z-index': activeItem == item.i ? 999 : 0 }"
            >
              <div class="grid-content">
                <div class="content-title">
                  <input
                    class="content-title-text"
                    type="text"
                    v-model="item.title"
                    :disabled="!titleEditable"
                    v-on:input="autoSave"
                  />
                  <div class="drag-area" v-show="draggable">
                    <span class="el-icon-rank"></span>
                  </div>
                  <div class="remove" v-show="removable">
                    <span
                      class="el-icon-close"
                      @click="removeItem(item.i)"
                    ></span>
                  </div>
                </div>
                <div class="content-text">
                  <quill-editor
                    class="text-area"
                    ref="myTextEditor"
                    v-model="item.content"
                    :options="editorOption"
                    @change="autoSave"
                    @focus="onEditorFocus(item.i)"
                  />
                </div>
              </div>
            </grid-item>
          </grid-layout>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { GridLayout, GridItem } from 'vue-grid-layout'
import { quillEditor } from 'vue-quill-editor'
import 'quill/dist/quill.bubble.css'
import TokenService from '../util/TokenService'
import DocService from '../util/DocService'
import domtoimage from 'dom-to-image'
import FileSaver from 'file-saver'
// import html2canvas from 'html2canvas'
export default {
  components: {
    GridLayout,
    GridItem,
    quillEditor
  },
  data () {
    return {
      loading: false,
      token: null,
      pageData: null,
      doc: {
        doc_title: '',
        template_title: '',
        layout: []
      },
      onCanvasEdit: false,
      draggable: false,
      resizable: false,
      removable: false,
      titleEditable: false,
      verticalCompact: true,
      index: 0,
      colNum: 12,
      activeItem: 0,
      showOperationArea: true,
      fromPath: '',
      editorOption: {
        theme: 'bubble',
        placeholder: '',
        modules: {
          toolbar: [
            ['bold', 'italic', 'underline'],
            [{ list: 'ordered' }, { list: 'bullet' }],
            [{ align: [] }],
            [{ color: [] }, { background: [] }],
            // ['image'],
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            ['clean']
          ]
        }
      }
    }
  },
  watch: {
    //
  },
  async created () {
    // 查看state里的登录状态，如果没登录去登录
    if (!this.$store.getters.getIsLogin) {
      localStorage.setItem('from', this.$route.path)
      return this.$router.push('/login')
    }
    // 更新token，如果token过期，就去登录，这时候基本上可以token是有效的了
    const token = await TokenService.checkToken()
    if (!token) {
      localStorage.setItem('from', this.$route.path)
      return this.$router.push('/login')
    }
    this.token = token
    // 去获取doc
    const doc = await DocService.getOneDoc(this.$route.params.id, this.token)
    if (doc.code === '70000') return this.$router.push('/document')
    this.doc = doc
    document.title = this.doc.doc_title + ' - 帛马'
    // 获取当前文档所有box里最大的那个box的i，设置到this.index，否则增减的时候会出现重复的i
    const boxData = this.doc.layout
    const maxItem = boxData.reduce((prev, cur) => {
      return prev.i > cur.i ? prev : cur
    })
    this.index = parseInt(maxItem.i) + 1
  },
  mounted () {
    // 支持ctrl+s快捷键保存
    document.onkeydown = (e) => {
      const key = window.event.keyCode
      if (key === 83 && e.ctrlKey && this.$route.name === 'DocCanvas') {
        window.event.preventDefault()
        this.autoSave('manual', false)
      }
    }
    // 如果是从temp来的
    // 这一部分需要优化掉
    if (localStorage.getItem('saveFromTemp')) {
      const userGroup = this.$store.getters.getUserGroup
      if (userGroup === 'VIP' || userGroup === '管理员') {
        // 如果是VIP用户或者管理员，自动打开所有画布上的编辑选项
        // this.toggleEdit()
        // 临时改成不管是什么用户，都只打开编辑面板，画布上不展开功能了，因为有个move的bug
        // this.activateCanvasEdit = true
      } else {
        // 如果是普通用户，只显示编辑面板，画布上的编辑功能不显示
        // this.activateCanvasEdit = true
      }
      // 如果是从temp来的，会在localstorage里创建一个saveFromTemp的标记，进来docCanvas后就移除掉
      localStorage.removeItem('saveFromTemp')
    }
  },
  methods: {
    // 如果是返回时保存，使用goback返回文档列表页
    async autoSave (type, goback) {
      const docToSave = {
        _id: this.doc._id,
        doc_title: this.doc.doc_title,
        layout: this.doc.layout
      }
      if (type === 'manual') {
        const doc = await DocService.updateDoc(this.token, docToSave)
        if (doc.code === 60005) {
          if (goback === true) {
            this.$router.push('/document')
          }
          // 更新页面title
          document.title = this.doc.doc_title + ' - 帛马'
          // document.title = this.doc.doc_title + ' - ' + this.doc.template_title + ' - 帛马'
          return this.$message({
            message: doc.message,
            type: 'success',
            offset: 52
          })
        }
        this.$message({
          message: doc.message,
          type: 'warning',
          offset: 52
        })
      }
    },
    // 如果普通用户点击了VIP功能，调用这个方法提示
    // 这里还得再加一个已经过期的VIP不能使用的提示，后面需要添加
    notifyVipLimit () {
      this.$confirm('自定义模板为VIP用户专享功能，请升级后使用。', '提示', {
        confirmButtonText: '保存文档，查看详情',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.autoSave('manual', false)
        this.$router.push('/price')
      }).catch(() => {})
    },
    notifyExportLimit () {
      this.$confirm('导出功能为VIP用户专享功能，请升级后使用。', '提示', {
        confirmButtonText: '保存文档，查看详情',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.autoSave('manual', false)
        this.$router.push('/price')
      }).catch(() => {})
    },
    startEdit () {
      // 显示编辑面板
      this.onCanvasEdit = true
      // 如果是免费用户，因为不能使用编辑功能，所以不给自动开启编辑功能
      if (this.$store.getters.getUserGroup === '免费用户') return
      this.draggable = !this.draggable
      this.resizable = !this.resizable
      this.removable = !this.removable
      this.titleEditable = !this.titleEditable
      this.editTempTitle = !this.editTempTitle
      // this.autoSave()
    },
    // 结束编辑，关闭所有编辑功能
    endEdit () {
      this.onCanvasEdit = false
      this.draggable = false
      this.resizable = false
      this.removable = false
      this.titleEditable = false
      this.editTempTitle = false
    },
    // toggleVerticalCompact () {
    //   // 如果是普通用户，不能使用编辑功能
    //   // if (this.$store.getters.getUserGroup === '免费用户') return this.notifyVipLimit()
    //   this.verticalCompact = !this.verticalCompact
    //   // this.autoSave()
    // },
    toggleDraggable () {
      // 如果是普通用户，不能使用编辑功能
      if (this.$store.getters.getUserGroup === '免费用户') return this.notifyVipLimit()
      this.draggable = !this.draggable
    },
    toggleResizable () {
      // 如果是普通用户，不能使用编辑功能
      if (this.$store.getters.getUserGroup === '免费用户') return this.notifyVipLimit()
      this.resizable = !this.resizable
    },
    toggleRemovable () {
      // 如果是普通用户，不能使用编辑功能
      if (this.$store.getters.getUserGroup === '免费用户') return this.notifyVipLimit()
      this.removable = !this.removable
    },
    toggleTitleEditable () {
      // 如果是普通用户，不能使用编辑功能
      if (this.$store.getters.getUserGroup === '免费用户') return this.notifyVipLimit()
      this.titleEditable = !this.titleEditable
    },
    // 返回前的确认动作
    confirmReturn () {
      this.$confirm('是否保存当前修改内容？', '提示', {
        distinguishCancelAndClose: true,
        confirmButtonText: '保存',
        cancelButtonText: '不保存',
        type: 'warning'
      })
        .then(() => {
          this.autoSave('manual', true)
        })
        .catch((action) => {
          if (action === 'cancel') window.location.href = '/#/document'
        })
    },
    async exportDocx () {
      // 如果是普通用户，不能使用导出word文档功能
      if (this.$store.getters.getUserGroup === '免费用户') return this.notifyExportLimit()
      // 获取当前的doc内容来作为导出
      const docToSave = {
        _id: this.doc._id,
        doc_title: this.doc.doc_title,
        layout: this.doc.layout
      }
      // 发起导出
      await DocService.exportToDocx(this.token, docToSave)
    },
    async exportPptx () {
      // 如果是普通用户，不能使用导出ppt文档功能
      if (this.$store.getters.getUserGroup === '免费用户') return this.notifyExportLimit()
      // 获取当前的doc内容来作为导出
      const docToSave = {
        _id: this.doc._id,
        doc_title: this.doc.doc_title,
        layout: this.doc.layout
      }
      // 发起导出
      await DocService.exportToPptx(this.token, docToSave)
    },
    // 导出图片
    async exportPNG () {
      // 如果是普通用户，不能使用编辑功能
      if (this.$store.getters.getUserGroup === '免费用户') return this.notifyExportLimit()
      // 设置成loading以免用户一直看着编辑界面
      this.loading = true
      const node = document.getElementById('pdfDom')

      // 过滤operation-area
      function filter (node) {
        if (node.classList) return !node.classList.contains('operation-area')
        return true
      }
      await domtoimage
        .toBlob(node, {
          filter: filter,
          style: { transform: 'scale(1, 1)' }
        })
        .then((blob) => {
          FileSaver.saveAs(blob, this.doc.doc_title + '.png')
          this.loading = false
        })
        .catch((err) => {
          this.$message({
            message: '保存失败，请重试',
            type: 'warning',
            offset: 52
          })
          this.loading = false
          console.log(err)
        })
    },
    // 删除文档确认
    confirmDelete () {
      this.$confirm('确认删除此文档？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.deleteDoc(this.$route.params.id)
        })
        .catch(() => {})
    },
    // 删除文档
    async deleteDoc (id) {
      const doc = await DocService.deleteDoc(this.token, id)
      if (doc.code === 60005) {
        this.$message({
          type: 'success',
          message: '删除成功',
          offset: 52
        })
        this.$router.push('/document')
      }
    },
    onEditorFocus (i) {
      this.activeItem = i
    },
    addItem: function () {
      // 如果是普通用户，不能使用编辑功能
      if (this.$store.getters.getUserGroup === '免费用户') return this.notifyVipLimit()
      // Add a new item. It must have a unique key!
      this.doc.layout.push({
        x: (this.doc.layout.length * 2) % (this.colNum || 12),
        y: this.doc.layout.length + (this.colNum || 12), // puts it at the bottom
        w: 2,
        h: 2,
        i: this.index,
        title: '新标题',
        content: ''
      })
      // Increment the counter to ensure key is always unique.
      this.index++
      console.log('新增一个box后' + this.index)
      // this.autoSave()
    },
    removeItem: function (val) {
      const index = this.doc.layout.map((item) => item.i).indexOf(val)
      this.doc.layout.splice(index, 1)
      console.log('删除一个box后' + this.index)
      this.autoSave()
    }
  },
  computed: {
    editor () {
      return this.$refs.myTextEditor.quill
    }
  }
}
</script>

<style lang="scss" scoped>
.card {
  //   border: 1px solid #d3d2d2;
  border: 0px;
  border-radius: 5px;
  //   box-shadow: 0px 0px 10px rgba(69, 101, 173, 0.1);
  background-color: #fff;
  padding-bottom: 10px;
}
.vue-grid-item:not(.vue-grid-placeholder) {
  background: #e5e5e5;
  border-radius: 5px;
}
.vue-grid-item .resizing {
  opacity: 0.9;
}
.vue-grid-item .static {
  background: #cce;
}
.vue-grid-item .grid-content {
  font-size: 24px;
  text-align: center;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  height: 100%;
  width: 100%;
  background-color: #e5e5e5;
  border-radius: 5px;
}
.vue-grid-item .content-title {
  display: flex;
  justify-content: space-between;
}
.vue-grid-item .content-text {
  height: calc(100% - 42px);
  width: 100%;
}
.content-title-text {
  width: calc(100% - 42px);
  height: 40px;
  border: 0px;
  background-color: #e5e5e5;
  margin-left: 6px;
  font-size: 16px;
  font-weight: 600;
  color: #292929;
}
.content-title-text:focus {
  outline: 0px;
}
.vue-grid-item .minMax {
  font-size: 12px;
}
.vue-grid-item .add {
  cursor: pointer;
}
.drag-area {
  font-size: 16px;
  background-origin: content-box;
  box-sizing: border-box;
  cursor: pointer;
  color: gray;
  background-color: #e5e5e5;
  margin-top: 12px;
  margin-right: 6px;
}
.remove {
  font-size: 16px;
  margin-top: 12px;
  margin-right: 6px;
  background-origin: content-box;
  box-sizing: border-box;
  cursor: pointer;
  color: gray;
  background-color: #e5e5e5;
}
.page-header {
  margin-top: 10px;
  margin-bottom: 6px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.page-header p {
  font-family: '微软雅黑';
  font-size: 20px;
  font-weight: bold;
  margin-left: 10px;
  padding-top: 7px;
}
.page-title {
  font-size: 20px;
  font-weight: 600;
  line-height: 40px;
  // margin-left: 10px;
  color: #292929;
  border: 0px;
  background-color: #fff;
  width: 320px;
}
.page-title:focus {
  outline: 0px;
}
.operation-area {
  margin-right: 6px;
}
.btn-group {
  margin-right: 10px;
}
.columns {
  -moz-columns: 120px;
  -webkit-columns: 120px;
  columns: 120px;
}
.text-area {
  width: calc(100% - 4px);
  height: 100%;
  box-sizing: border-box;
  border: 0px;
  resize: none;
  margin: 0 auto 0 auto;
  font-size: 12px;
  padding: 2px;
  background-color: #fff;
  border-bottom-left-radius: 3px;
  border-top-right-radius: 3px;
}
</style>
