<template>
  <div>
    <el-table
      :data="
        tableData.filter(
          (data) =>
            !search || data.title.toLowerCase().includes(search.toLowerCase())
        )
      "
      style="width: 100%"
      stripe
      :default-sort="{ prop: 'updatedAt', order: 'descending' }"
    >
      <el-table-column label="文档名" prop="doc_title"> </el-table-column>
      <!-- <el-table-column label="模板" prop="template_title"> </el-table-column> -->
      <el-table-column label="修改时间" prop="updatedAt"> </el-table-column>
      <el-table-column align="right">
        <template slot-scope="scope">
          <el-button size="mini" @click="recoverDoc(scope.row._id)"
            >恢复</el-button
          >
          <el-button
            size="mini"
            @click="confirmPermanentDelete(scope.$index, scope.row)"
            >永久删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import DocService from '../util/DocService'
import TokenService from '../util/TokenService'

export default {
  data () {
    return {
      token: null,
      tableData: [],
      search: ''
    }
  },
  async created () {
    if (!this.$store.getters.getIsLogin) {
      localStorage.setItem('from', this.$route.path)
      return this.$router.push('/login')
    }
    // 验证登录获取token
    const token = await TokenService.checkToken()
    if (!token) {
      localStorage.setItem('from', this.$route.path)
      return this.$router.push('/login')
    }
    this.token = token
    this.getDocs()
    document.title = '回收站 - 帛马'
  },
  methods: {
    // 获取回收站文档列表
    async getDocs () {
      const docList = await DocService.getRecycle(this.token)
      this.tableData = docList
    },
    // 创建文档按钮，先去选择模板
    createNew () {
      this.$router.push('/template')
    },
    // 确认删除文档
    confirmDelete (index, row) {
      this.$confirm('确认删除该文档？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.deleteDoc(row._id)
        })
        .catch(() => {})
    },
    async deleteDoc (id) {
      // 删除文档
      const doc = await DocService.deleteDoc(this.token, id)
      if (doc.code === 60005) {
        this.$message({
          type: 'success',
          message: '删除成功',
          offset: 52
        })
        this.getDocs()
      }
    },
    async recoverDoc (id) {
      // 恢复文档
      const doc = await DocService.recoverDoc(this.token, id)
      if (doc.code === 60005) {
        this.$message({
          type: 'success',
          message: '恢复成功，请到我的文档中查看',
          offset: 52
        })
        this.getDocs()
      }
    },
    // 确认永久删除文档
    confirmPermanentDelete (index, row) {
      this.$confirm('永久删除后将不可恢复，是否永久删除该文档？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.permanentDeleteDoc(row._id)
        })
        .catch(() => {})
    },
    async permanentDeleteDoc (id) {
      // 删除文档
      const doc = await DocService.permanentDeleteDoc(this.token, id)
      if (doc.code === 60005) {
        this.$message({
          type: 'success',
          message: '永久删除成功',
          offset: 52
        })
        this.getDocs()
      }
    },
    handleEdit (index, row) {
      console.log(index, row)
      this.$router.push('/doc/' + row._id)
    },
    handleDelete (index, row) {
      console.log(index, row)
    }
  }
}
</script>

<style scope="scoped"></style>
