<template>
  <div>
    <div style="text-align: left">
      <el-row>
        <el-button type="success" @click="createNew">选择模板新建</el-button>
        <el-button type="success" @click="createBlankDoc">新建空白文档</el-button>
      </el-row>
    </div>
    <el-table
      :data="
        tableData.filter(
          (data) =>
            !search ||
            data.doc_title.toLowerCase().includes(search.toLowerCase())
        )
      "
      style="width: 100%"
      stripe
      :default-sort = "{prop: 'updatedAt', order: 'descending'}"
    >
      <el-table-column label="文档名" prop="doc_title" sortable>
        <template slot-scope="scope">
          <a :href="'/#/doc/' + scope.row._id" style="text-decoration: none; color: #606266;">{{scope.row.doc_title}}</a>
        </template>
      </el-table-column>
      <!-- <el-table-column label="模板" prop="template_title" sortable> </el-table-column> -->
      <el-table-column label="修改时间" prop="updatedAt" sortable> </el-table-column>
      <el-table-column align="right">
        <template #header>
          <el-input v-model="search" size="small" placeholder="搜索文件名" />
        </template>
        <template slot-scope="scope">
          <el-button
            size="mini"
            type="success"
            @click="handleEdit(scope.$index, scope.row)"
            >编辑</el-button
          >
          <el-button size="mini" @click="confirmDelete(scope.$index, scope.row)"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import DocService from '../util/DocService'
import TokenService from '../util/TokenService'

export default {
  data () {
    return {
      token: null,
      tableData: [],
      search: ''
    }
  },
  async created () {
    // 查看state里的登录状态，如果没登录去登录
    if (!this.$store.getters.getIsLogin) {
      localStorage.setItem('from', this.$route.path)
      return this.$router.push('/login')
    }
    // 验证登录获取token
    const token = await TokenService.checkToken()
    if (!token) {
      localStorage.setItem('from', this.$route.path)
      return this.$router.push('/login')
    }
    this.token = token
    // 获取文档列表
    this.getDocs()
    document.title = '我的文档 - 帛马'
  },
  methods: {
    // 获取文档列表
    async getDocs () {
      const docList = await DocService.getDocs(this.token)
      this.tableData = docList
    },
    // 创建文档按钮，先去选择模板
    createNew () {
      this.$router.push('/template')
    },
    // 创建空白文档
    createBlankDoc () {
      this.$router.push('/temp/62ce5398c3c16f51c604ade1')
    },
    // 编辑文档
    handleEdit (index, row) {
      this.$router.push('/doc/' + row._id)
    },
    // 删除文档确认
    confirmDelete (index, row) {
      this.$confirm('确认删除该文档？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.deleteDoc(row._id)
        })
        .catch(() => {})
    },
    // 删除文档
    async deleteDoc (id) {
      const doc = await DocService.deleteDoc(this.token, id)
      if (doc.code === 60005) {
        this.$message({
          type: 'success',
          message: '删除成功',
          offset: 52
        })
        this.getDocs()
      }
    }
  }
}
</script>

<style scope="scoped"></style>
